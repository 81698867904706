<template>
  <base-section id="info-alt" space="56" style="text-align: center;">
    Write us at <span style="font-weight: bold;">info@tentifly.com</span> or
    <div
      style="padding-bottom: 32px;padding-top: 8px; font-weight: 600; font-size: large; text-align: center;"
    >
      <router-link :to="{ name: 'Contact' }">
        <base-btn>
          Click here & let's chat!
        </base-btn>
      </router-link>
    </div>
    <v-container style="display: none">
      <v-row justify="space-between">
        <v-col style="margin: auto;" cols="12" md="5">
          <base-business-info title="KEEP IN TOUCH WITH US">
          </base-business-info>
        </v-col>
        <v-col style="display: none;" cols="12" md="5">
          <base-business-info title="KEEP IN TOUCH WITH US">
          </base-business-info>
        </v-col>

        <v-col style="display: none;" cols="12" md="6">
          <base-contact-form title="MAIL US YOUR MESSAGE" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionKeepInTouch"
};
</script>
